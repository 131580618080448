import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninPageComponent } from './pages/signin-page/signin-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { RefreshTokenComponent } from './pages/refresh-token/refresh-token.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { SsoAuthPageComponent } from './pages/sso-auth-page/sso-auth-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PolicyPageComponent } from './pages/policy-page/policy-page.component';

import { LoaderComponent } from './shared/components/loader/loader.component';
import { SocialsLoginComponent } from './shared/components/socials-login/socials-login.component';
import { ChangePasswordModalComponent } from './shared/components/modals/change-password-modal/change-password-modal.component';
import { SetPasswordModalComponent } from './shared/components/modals/set-password-modal/set-password-modal.component';
import { WalletPasswordModalComponent } from './shared/components/modals/wallet-password-modal/wallet-password-modal.component';
import { EditAvatarModalComponent } from './shared/components/modals/edit-avatar-modal/edit-avatar-modal.component';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    SigninPageComponent,
    SignupPageComponent,
    RefreshTokenComponent,
    LogoutComponent,
    ForgotPasswordPageComponent,
    ResetPasswordPageComponent,
    ProfilePageComponent,
    SsoAuthPageComponent,
    TermsPageComponent,
    PolicyPageComponent,
    ChangePasswordModalComponent,
    SetPasswordModalComponent,
    WalletPasswordModalComponent,
    EditAvatarModalComponent,
    SocialsLoginComponent,
    LoaderComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      closeButton: true,
      timeOut: 5000,
      preventDuplicates: true
    }),
    NgOptimizedImage,
    FormsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ]
})
export class AppModule {}
