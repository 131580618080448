import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { ChangePasswordModalComponent } from '../../shared/components/modals/change-password-modal/change-password-modal.component';
import { SetPasswordModalComponent } from '../../shared/components/modals/set-password-modal/set-password-modal.component';
import { EditAvatarModalComponent } from '../../shared/components/modals/edit-avatar-modal/edit-avatar-modal.component';
import { UserService } from '../../shared/services/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { WalletService } from '../../shared/services/wallet.service';
import { UserInfo, UpdateUserInfo } from '../../shared/intarfaces';
import { AUTH_METHODS } from 'src/app/shared/enums';

@Component({
  selector: 'profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
  @AutoUnsubscribe()
  queryParamsSub: Subscription | undefined;
  @AutoUnsubscribe()
  userInfoSub: Subscription | undefined;

  ssoGoogleAuthUrl: string = '';
  ssoDiscordAuthUrl: string = '';

  nameMinLength: number = 2;
  nameMaxLength: number = 50;

  usernameMinLength: number = 3;
  usernameMaxLength: number = 50;
  usernameSpecialCharacters = `!";#$%&'()*+,-./:;<=>?@\\[\\]^_\`{|}~`;

  form: FormGroup = new FormGroup({});

  constructor(
    public userService: UserService,
    public authService: AuthService,
    private walletService: WalletService,
    private toastrService: ToastrService,
    private dialogService: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.userService.userInfo$.next({} as UserInfo);
    this.userService.getUserInfo();

    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      const confirmationToken = params['confirmationToken'];
      if (confirmationToken) {
        this.userService.confirmationTokenRequest(confirmationToken).subscribe((response) => {
          if (response.success) {
            this.toastrService.success('Your email address has been successfully confirmed!');
          } else {
            this.toastrService.error(response.error?.description);
          }
          this.router.navigate(['/profile']);
        });
      }
    });

    this.ssoGoogleAuthUrl = this.authService.getGoogleAuthUrl(AUTH_METHODS.connect);
    this.ssoDiscordAuthUrl = this.authService.getDiscordAuthUrl(AUTH_METHODS.connect);

    this.userInfoSub = this.userService.userInfo$.subscribe((data) => {
      if (!data.userId) {
        return;
      }

      if (!data.wallet) {
        this.walletService.createOpenFortWallet();
      } else if (this.authService.returnURL !== '') {
        this.authService.handleRedirect();
      }

      this.form = new FormGroup({
        userId: new FormControl({ value: data.userId, disabled: true }, [Validators.required]),
        firstName: new FormControl(data.firstName, [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(this.nameMaxLength),
          Validators.pattern('^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$')
        ]),
        lastName: new FormControl(data.lastName, [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(this.nameMaxLength),
          Validators.pattern('^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$')
        ]),
        email: new FormControl({ value: data.email, disabled: true }, [Validators.required]),
        username: new FormControl(data.username, [
          Validators.minLength(this.usernameMinLength),
          Validators.maxLength(this.usernameMaxLength),
          Validators.pattern(
            new RegExp(
              `^(?![${this.usernameSpecialCharacters}])` +
                `([a-zA-Z0-9${this.usernameSpecialCharacters}]*)` +
                `(?![${this.usernameSpecialCharacters}])$`
            )
          )
        ]),
        imageUrl: new FormControl(data.imageUrl)
      });
      this.form.markAllAsTouched();
    });
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    const { firstName, lastName, username, imageUrl } = this.form.value;
    const tempProfile: UpdateUserInfo = { firstName, lastName, username: username !== '' ? username : null, imageUrl };
    this.updateUserProfile(tempProfile);
  }

  updateUserProfile(userData: UpdateUserInfo) {
    this.userService.updateUserInfoRequest(userData).subscribe((response) => {
      if (response.success && response.data) {
        this.userService.userInfo$.next(response.data);
        this.toastrService.success('Profile updated successfully.');
      } else if (response?.error?.description.includes('username already exists')) {
        this.form?.get?.('username')?.setErrors({
          notUnique: true
        });
      }
    });
  }

  onAvatarEdit() {
    this.dialogService.open(EditAvatarModalComponent, {
      panelClass: 'dialog-overlay-pane'
    });
  }

  changePassword(event: any) {
    event.preventDefault();
    this.dialogService.open(ChangePasswordModalComponent, {
      panelClass: 'dialog-overlay-pane'
    });
  }

  setPassword(event: any) {
    event.preventDefault();
    this.dialogService.open(SetPasswordModalComponent, {
      panelClass: 'dialog-overlay-pane'
    });
  }

  disconnectGoogle() {
    this.authService.disconnectGoogleRequest().subscribe((response) => {
      if (response.success) {
        this.userService.getUserInfo();
        this.toastrService.success('Your Google account disconnected successfully!');
      }
    });
  }

  disconnectDiscord() {
    this.authService.disconnectDiscordRequest().subscribe((response) => {
      if (response.success) {
        this.userService.getUserInfo();
        this.toastrService.success('Your Discord account disconnected successfully!');
      }
    });
  }

  logout() {
    this.authService.logout();
  }
}
