import { Component } from '@angular/core';

@Component({
    selector: 'terms-page',
    templateUrl: './terms-page.component.html',
    styleUrls: ['./terms-page.component.scss'],
    standalone: false
})
export class TermsPageComponent {
  constructor() {}
}
