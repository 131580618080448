import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ThemeVariant } from '../interfaces';
import { DOCUMENT } from '@angular/common';

const THEME_KEY = 'theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeSrc = new BehaviorSubject<ThemeVariant>(this.getThemeFromStorage());
  theme$ = this.themeSrc.asObservable();
  get themeSnapshot() {
    return this.themeSrc.getValue();
  }

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  setTheme(theme: ThemeVariant) {
    const html = this.document.querySelector('html')!;
    
    this.themeSrc.next(theme);
    html.style.colorScheme = theme;
    html.classList.add(theme);
    localStorage.setItem(THEME_KEY, theme);
  }

  private getThemeFromStorage() {
    const theme = localStorage.getItem(THEME_KEY);

    if (theme !== 'dark' && theme !== 'light') {
      return 'dark';
    }

    return theme;
  }
}
