import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';
import { ValidatorsService } from '../../../services/validators.service';

@Component({
    selector: 'set-password-modal',
    templateUrl: './set-password-modal.component.html',
    styleUrls: ['./set-password-modal.component.scss'],
    standalone: false
})
export class SetPasswordModalComponent {
  username: string = this.userService.userInfo$.getValue().username || '';
  form: FormGroup = new FormGroup(
    {
      password: new FormControl('', this.validatorsService.passwordValidators(true, true, this.username)),
      repeatPassword: new FormControl('', [Validators.required])
    },
    { validators: this.validatorsService.fieldsMatchValidator('password', 'repeatPassword', true) }
  );

  get passwordControl() {
    return this.form.get('password')!;
  }

  constructor(
    private dialogRef: MatDialogRef<SetPasswordModalComponent>,
    private toastrService: ToastrService,
    private authService: AuthService,
    public userService: UserService,
    public validatorsService: ValidatorsService
  ) {}

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }
    const email = this.userService.userInfo$.getValue().email;
    const passwordHash = await this.authService.hashPassword(this.form.value.password, email);

    this.authService.setPasswordRequest(passwordHash).subscribe((response) => {
      if (response.success) {
        this.userService.loadUserInfo();
        this.toastrService.success('Password added successfully!');
        this.closeModal();
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
