import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { countries, ICountry } from 'countries-list'

@Component({
  selector: 'country-select',
  templateUrl: './country-select.component.html',
  styleUrl: './country-select.component.scss',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountrySelectComponent implements OnInit {
  @Input() formControl!: FormControl;
  searchSelectCtrl: FormControl = new FormControl();
  COUNTRIES_LIST = Object.values(countries);
  filteredCountries = new BehaviorSubject<ICountry[]>(this.COUNTRIES_LIST);

  ngOnInit() {
    this.searchSelectCtrl.valueChanges.subscribe(() => {
      this.filter();
    });
  }

  filter() {
    this.filteredCountries.next(
      this.COUNTRIES_LIST.filter((country) => {
        return country.name.toLowerCase().includes(this.searchSelectCtrl.value.toLowerCase());
      })
    );
  }
}
