import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';
import { AuthService } from '../../../services/auth.service';
import { ValidatorsService } from '../../../services/validators.service';
import { ChangePasswordRequest } from '../../../intarfaces';

@Component({
  selector: 'change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  username: string = this.userService.userInfo$.getValue().username || '';
  form: FormGroup = new FormGroup(
    {
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', this.validatorsService.passwordValidators(true, true, this.username))
    },
    { validators: this.validatorsService.fieldsMatchValidator('oldPassword', 'newPassword', false) }
  );

  get passwordControl() {
    return this.form.get('newPassword')!;
  }

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    private toastrService: ToastrService,
    private authService: AuthService,
    public userService: UserService,
    public validatorsService: ValidatorsService
  ) {}

  ngOnInit(): void {}

  async onSubmit() {
    if (!this.form.valid) {
      return;
    }
    const email = this.userService.userInfo$.getValue().email;

    const oldPasswordHash = await this.authService.hashPassword(this.form.value.oldPassword, email);
    const oldPasswordFallbackHash = await this.authService.hashPassword(this.form.value.oldPassword, email, false);
    const newPasswordHash = await this.authService.hashPassword(this.form.value.newPassword, email);

    const changePasswordRequest: ChangePasswordRequest = {
      oldPassword: oldPasswordHash,
      oldPasswordFallback: oldPasswordFallbackHash,
      newPassword: newPasswordHash
    };

    this.userService.changePasswordRequest(changePasswordRequest).subscribe((response) => {
      if (response.success) {
        this.toastrService.success('Password changed successfully!');
        this.closeModal();
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
