<mat-select class="form__field" [formControl]="formControl">
  <mat-option>
    <ngx-mat-select-search
      placeholderLabel="Search"
      noEntriesFoundLabel="No countries found"
      [formControl]="searchSelectCtrl" />
  </mat-option>

  <mat-option [value]="null"> None </mat-option>

  @for (item of filteredCountries | async; track $index) {
    <mat-option [value]="item.name">
      {{ item.name }}
    </mat-option>
  }
</mat-select>
