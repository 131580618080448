import { Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../services/user.service';
import { UpdateUserInfo, UserInfo } from '../../../interfaces';

@Component({
    selector: 'app-edit-avatar-modal',
    templateUrl: './edit-avatar-modal.component.html',
    styleUrls: ['./edit-avatar-modal.component.scss'],
    standalone: false
})
export class EditAvatarModalComponent implements OnDestroy {
  @ViewChild('fileInput') fileInput!: ElementRef;
  profile: UserInfo;
  avatarPath: string;
  imageUrl: string = '';
  avatarUpdated: boolean = false;
  isSaveButtonPressed: boolean = false;
  image: File = {} as File;
  private maxFileSize = 10485760; // 10mb in bytes

  constructor(
    private toastrService: ToastrService,
    public userService: UserService,
    private dialogRef: MatDialogRef<EditAvatarModalComponent>
  ) {
    this.profile = this.userService.userInfo$.getValue();
    this.avatarPath = this.profile.imageUrl ? this.profile.imageUrl : '';
  }

  onChange(event: any) {
    if (event.target.files.length === 0) {
      return;
    }

    this.image = event.target.files[0];

    if (this.image.type.match('image/jpeg|image/png') == null) {
      this.avatarUpdated = false;
      this.toastrService.error('Attached file type is incorrect');
    } else if (this.image.size > this.maxFileSize) {
      this.avatarUpdated = false;
      this.toastrService.error('Attached file max-size is 10MB');
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(this.image);
      fileReader.onload = () => {
        this.avatarUpdated = true;
        this.imageUrl = this.image.name;
        this.profile.imageUrl = fileReader.result?.toString();
      };
    }
  }

  onSubmit() {
    this.closeModal();
    if (this.avatarUpdated) {
      this.isSaveButtonPressed = true;

      const formData = new FormData();
      formData.append('image', this.image);

      this.userService.uploadAvatarImageRequest(formData).subscribe((response) => {
        if (response.success && response.data) {
          this.profile.imageUrl = response.data;
          const { firstName, lastName, username, imageUrl } = this.profile;
          this.userService
            .updateUserInfoRequest({ firstName, lastName, username, imageUrl })
            .subscribe((responseUserAvatar) => {
              if (responseUserAvatar.success && responseUserAvatar.data) {
                this.userService.userInfo$.next(responseUserAvatar.data);
                this.toastrService.success('Profile photo updated successfully.');
              }
            });
        }
      });
    }
    this.avatarUpdated = false;
  }

  onRemove() {
    this.fileInput.nativeElement.value = '';
    this.avatarUpdated = false;
    this.imageUrl = '';
    this.profile.imageUrl = this.avatarPath;
  }

  onCancel() {
    this.fileInput.nativeElement.value = '';
    this.profile.imageUrl = this.avatarPath;
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      if (!this.isSaveButtonPressed) {
        this.profile.imageUrl = this.avatarPath;
      }
    }, 500);
  }
}
