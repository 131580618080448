import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, tap } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

const KEY = 'countdown_start_time';
const DEFAULT = 60;

@Component({
  selector: 'confirm-email-modal',
  templateUrl: './confirm-email-modal.component.html',
  styleUrls: ['./confirm-email-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ConfirmEmailModalComponent implements OnInit {
  isResendEnabled$ = new BehaviorSubject(false);

  @ViewChild('cd', { static: false }) private countdown!: CountdownComponent;

  config: CountdownConfig = {
    format: 'mm:ss',
  };

  constructor(
    private authService: AuthService,
    private  userService: UserService,
    private toastrService: ToastrService,
    private dialogRef: MatDialogRef<ConfirmEmailModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { email: string }
  ) { }

  ngOnInit(): void {
    const startTime = +localStorage.getItem(KEY)!!;

    if (startTime && !isNaN(startTime)) {
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - startTime;
      const remainingTime = DEFAULT - elapsedTime;

      if (remainingTime > 0) {
        this.config = { ...this.config, leftTime: remainingTime };
      } else {
        this.isResendEnabled$.next(true);
      }
    } else {
      this.config = { ...this.config, leftTime: DEFAULT };
      localStorage.setItem(KEY, `${Math.floor(Date.now() / 1000)}`);
    }
  }

  proceed() {
    this.authService.proceedLoginFlow();
  }

  handleCountdownEnd(event: CountdownEvent) {
    if (event.action === 'done') {
      this.isResendEnabled$.next(true);
    }
  }

  resend() {
    if (!this.isResendEnabled$.getValue()) return;

    this.authService.resendConfirmEmailRequest(this.data.email)
      .pipe(
        tap((response) => {
          if (!response.success) return;

          this.toastrService.success('Email sent successfully!');

          this.isResendEnabled$.next(false);
          localStorage.setItem(KEY, `${Math.floor(Date.now() / 1000)}`);
          this.config = { ...this.config, leftTime: DEFAULT };
          this.countdown.restart();
        })
      )
      .subscribe();
  }

  logout() {
    this.authService.closeConfirmEmailModal();
    this.authService.logout();
    localStorage.removeItem(KEY);
  }
}
