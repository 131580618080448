import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'logout',
  template: ''
})
export class LogoutComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.clearData();

    const returnURL = this.authService.returnURL;
    if (returnURL) {
      this.authService.encodedReturnURL = null;
      window.location.href = returnURL;
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
