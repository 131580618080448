import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'refresh-token',
  template: ''
})
export class RefreshTokenComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const refreshToken = this.authService.refreshToken;
    if (refreshToken) {
      this.authService
        .refreshTokenRequest()
        .pipe(
          tap((response) => {
            if (response.data) {
              this.authService.accessToken = response.data.accessToken;
              this.authService.refreshToken = response.data.refreshToken;
              if (this.authService.returnURL) {
                this.authService.handleRedirect();
              } else {
                this.router.navigate(['/profile']);
              }
            } else {
              this.authService.logout();
            }
          })
        )
        .subscribe();
    } else {
      console.log('Refresh token doesn`t exist');
      this.authService.logout();
      return;
    }
  }
}
