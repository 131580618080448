import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastrModule } from 'ngx-toastr';
import { CountdownModule } from 'ngx-countdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninPageComponent } from './pages/signin-page/signin-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { RefreshTokenComponent } from './pages/refresh-token/refresh-token.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { SsoAuthPageComponent } from './pages/sso-auth-page/sso-auth-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { PolicyPageComponent } from './pages/policy-page/policy-page.component';

import { LoaderComponent } from './shared/components/loader/loader.component';
import { SocialsLoginComponent } from './shared/components/socials-login/socials-login.component';
import { ChangePasswordModalComponent } from './shared/components/modals/change-password-modal/change-password-modal.component';
import { SetPasswordModalComponent } from './shared/components/modals/set-password-modal/set-password-modal.component';
import { WalletPasswordModalComponent } from './shared/components/modals/wallet-password-modal/wallet-password-modal.component';
import { EditAvatarModalComponent } from './shared/components/modals/edit-avatar-modal/edit-avatar-modal.component';
import { ErrorModalComponent } from './shared/components/modals/error-modal/error-modal.component';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { AuthService } from './shared/services/auth.service';
import { ConfirmEmailModalComponent } from './shared/components/modals/confirm-email-modal/confirm-email-modal.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CountrySelectComponent } from './shared/components/country-select/country-select.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninPageComponent,
    SignupPageComponent,
    RefreshTokenComponent,
    LogoutComponent,
    ForgotPasswordPageComponent,
    ResetPasswordPageComponent,
    ProfilePageComponent,
    SsoAuthPageComponent,
    TermsPageComponent,
    PolicyPageComponent,
    ChangePasswordModalComponent,
    SetPasswordModalComponent,
    WalletPasswordModalComponent,
    EditAvatarModalComponent,
    ErrorModalComponent,
    SocialsLoginComponent,
    LoaderComponent,
    ConfirmEmailModalComponent,
    CountrySelectComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      closeButton: true,
      timeOut: 5000,
      preventDuplicates: true
    }),
    NgOptimizedImage,
    FormsModule,
    CountdownModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxMatSelectSearchModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    provideEnvironmentNgxMask(),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  constructor(private authService: AuthService) {
    this.entryPoint();
  }

  // called in appModule to start flow as early as possible
  private entryPoint() {
    const params = new URLSearchParams(window.location.search);
    let returnUrl = params.get('returnURL') || undefined;
    const accessToken = params.get('accessToken') || undefined;


    if (window.location.href.includes('auth/callback')) {
      returnUrl = localStorage.getItem('returnUrl') || undefined;
    }

    this.authService.startFlow({
      returnUrl,
      accessToken
    });
  }
}
