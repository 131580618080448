import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import {
  UserSignInRequest,
  UserSignUpRequest,
  UserResetPasswordRequest,
  AuthTokens,
  StatusResponse,
  Response
} from '../intarfaces';
import { AUTH_TYPES, AUTH_METHODS } from '../../shared/enums';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  set accessToken(credentials: string) {
    localStorage.setItem('accessToken', credentials);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken')!.toString() : '';
  }

  set refreshToken(credentials: string) {
    localStorage.setItem('refreshToken', credentials);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken')!.toString() : '';
  }

  set utmSourceId(utmSourceId: string) {
    localStorage.setItem('utmSourceId', utmSourceId);
  }

  get utmSourceId(): string {
    return localStorage.getItem('utmSourceId') ? localStorage.getItem('utmSourceId')!.toString() : '';
  }

  private _encodedReturnURL: string | null = null;

  set encodedReturnURL(returnURL: string | null) {
    this._encodedReturnURL = returnURL;
  }

  get returnURL(): string {
    return decodeURIComponent(atob(this._encodedReturnURL || ''));
  }

  set userData(userData: string) {
    localStorage.setItem('userData', userData);
  }

  get userData(): string {
    return localStorage.getItem('userData') ? localStorage.getItem('userData')!.toString() : '';
  }

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router
  ) {
    if (this.refreshToken) {
      this.isLoggedIn$.next(true);
    }
  }

  getPresetUserData(): UserSignUpRequest | null {
    return this.userData !== '' ? JSON.parse(atob(this.userData)) : null;
  }

  successAuthorize(tokenData: AuthTokens) {
    this.refreshToken = tokenData.refreshToken;
    this.accessToken = tokenData.accessToken;
    this.isLoggedIn$.next(true);
    localStorage.removeItem('userData');

    this.getUserInfoRequest().subscribe((response) => {
      if (response.success && response.data) {
        if (response.data.wallet && this.returnURL) {
          this.handleRedirect();
          return;
        }
      }
      this.router.navigateByUrl('/profile');
    });
  }

  handleRedirect() {
    const returnURL = this.returnURL;
    const accessToken = this.accessToken;
    const refreshToken = this.refreshToken;
    const separator = returnURL.includes('?') ? '&' : '?';
    this.encodedReturnURL = null;
    window.location.href = returnURL + separator + 'accessToken=' + accessToken + '&refreshToken=' + refreshToken;
  }

  refreshTokenRequest() {
    const refreshToken: string = this.refreshToken;

    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/auth/token/refresh`, { refreshToken });
  }

  async hashPassword(password: string, salt: string, toLowerCase: boolean = true): Promise<string> {
    const encoder = new TextEncoder();
    const passwordKey = encoder.encode(password);
    if (toLowerCase) {
      salt = salt.toLowerCase();
    }
    const saltKey = encoder.encode(salt);

    // Import the password as a CryptoKey object
    const key = await crypto.subtle.importKey('raw', passwordKey, { name: 'PBKDF2' }, false, ['deriveBits']);

    // Derive the key using PBKDF2
    const derivedBits = await crypto.subtle.deriveBits(
      {
        name: 'PBKDF2',
        salt: saltKey,
        iterations: 100000, // number of iterations
        hash: 'SHA-256'
      },
      key,
      256 // result hash length in bits
    );

    // Convert the derived bits to a hex string
    const hashArray = Array.from(new Uint8Array(derivedBits));
    const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }

  getGoogleAuthUrl(method: AUTH_METHODS): string {
    const authUrl = environment.oauth[AUTH_TYPES.google].authUrl;
    const clientId = environment.oauth[AUTH_TYPES.google].clientId;
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.google, method);
    const state = this.generateState();

    return `${authUrl}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}`;
  }

  getDiscordAuthUrl(method: AUTH_METHODS): string {
    const authUrl = environment.oauth[AUTH_TYPES.discord].authUrl;
    const clientId = environment.oauth[AUTH_TYPES.discord].clientId;
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.discord, method);

    return `${authUrl}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}`;
  }

  getRedirectUrl(type: AUTH_TYPES, method: AUTH_METHODS): string {
    return environment.oauth[type][method];
  }

  generateState(): string {
    return Math.random().toString(36).substring(2);
  }

  sendLoginRequest(userData: UserSignInRequest) {
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/auth/token/password`, userData);
  }

  sendRegisterRequest(userData: UserSignUpRequest) {
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/user/sign-up/password`, userData);
  }

  sendGoogleAuthRequest(code: string, method: AUTH_METHODS) {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.google, method);
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/auth/token/google`, {
      code: code,
      redirectUri: redirectUri
    });
  }

  sendDiscordAuthRequest(code: string, method: AUTH_METHODS) {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.discord, method);
    return this.http.post<Response<AuthTokens>>(`${environment.gaiminApi}/auth/token/discord`, {
      code: code,
      redirectUri: redirectUri
    });
  }

  setPasswordRequest(data: string) {
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/add-sign-in/password`,
      { password: data },
      {
        headers: this.authorizationHeader()
      }
    );
  }

  resetPasswordRequest(data: UserResetPasswordRequest) {
    return this.http.post<StatusResponse>(`${environment.gaiminApi}/user/password/confirm-reset`, data);
  }

  connectGoogleRequest(code: string) {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.google, AUTH_METHODS.connect);
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/add-sign-in/google`,
      { code: code, redirectUri: redirectUri },
      {
        headers: this.authorizationHeader()
      }
    );
  }

  connectDiscordRequest(code: string) {
    const redirectUri = this.getRedirectUrl(AUTH_TYPES.discord, AUTH_METHODS.connect);
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/add-sign-in/discord`,
      { code: code, redirectUri: redirectUri },
      {
        headers: this.authorizationHeader()
      }
    );
  }

  disconnectGoogleRequest() {
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/remove-sign-in/google`,
      {},
      {
        headers: this.authorizationHeader()
      }
    );
  }

  disconnectDiscordRequest() {
    return this.http.post<StatusResponse>(
      `${environment.gaiminApi}/user/me/remove-sign-in/discord`,
      {},
      {
        headers: this.authorizationHeader()
      }
    );
  }

  getUserInfoRequest() {
    return this.http.get<any>(environment.gaiminApi + '/user/me', {
      headers: this.authorizationHeader()
    });
  }

  logout() {
    this.clearData();
    this.router.navigateByUrl('/');
  }

  clearData() {
    this.isLoggedIn$.next(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  authorizationHeader() {
    return new HttpHeaders().set('Authorization', `Bearer ${this.accessToken}`);
  }
}
